import React from "react";
import classNames from "classnames";
import { Container, Text } from "@atoms";

const DetailsGrid = ({ background, details }) => {
  return (
    <Container>
      {!!details?.length && (
        <div
          className={classNames(
            "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3",
            {
              "text-white":
                background === "bg-black" ||
                background === "bg-teal-dark" ||
                background === "bg-purple" ||
                background === "bg-pink",
            }
          )}
        >
          {details.map((detail, i) => {
            const { uid, heading, descriptor } = detail;
            return (
              <div
                key={uid}
                className={classNames(
                  "flex flex-col gap-3 border-teal-light pb-5 text-center last:pb-0 sm:text-left",
                  {
                    // three columns
                    "pr-5 md:border-l-0 md:pl-0": i % 3 === 0,
                    "md:border-l-2 md:px-5": i % 3 === 1,
                    "md:border-l-2 md:pl-5 md:pr-0": i % 3 === 2,
                    "md:border-t-0 md:pt-0": i < 3,
                    // two columns
                    "sm:pr-5": i % 2 === 0,
                    "sm:border-l-2 sm:pl-5": i % 2 === 1,
                    "sm:border-t-0 sm:pt-0": i < 2,
                    "sm:border-t-2 sm:pt-5": i >= 2,
                    // one column
                    "border-t-2 pt-5": i !== 0,
                  }
                )}
              >
                <Text variant="h6" className="text-teal-light">
                  {heading}
                </Text>
                <Text variant="xs">{descriptor}</Text>
              </div>
            );
          })}
        </div>
      )}
    </Container>
  );
};

DetailsGrid.defaultProps = {};

export default DetailsGrid;
